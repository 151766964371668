.bottomButtonStyle {
    position: absolute;
    bottom: 20;
}

.bottomImageStyles {
    position: absolute;
    bottom: 0;
}

.bottomImageStylesCollapsed {
    width: 5em
}

.bottomButtonStyleUnCollapsed {
    width: 12em
}

.menus {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.menus:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.sidebarOpen {
    background-color: #1E497D;
}

.active-menu {
    background-color: #1E497D;
}

/* Ignite Grid  */

.gridDataContainer {
    text-transform: capitalize;
    font-family: 'Montserrat', serif;
}

@media (max-width: 1024px) {

    .sideContainer {
        height: calc(100% - 250px);
    }

    .menus p {
        white-space: nowrap;
        overflow: scroll;
        text-overflow: ellipsis;
    }

}

#grid {
    --header-background: #FAF9F6;
    --header-text-color: #202020;
    --ig-grid-cell-bg: #FFCD0F;
    --ig-grid-header-bg: #f5f5f5;
    --ig-grid-header-text-color: #444;

    /* Main Column Actions styles */
    --ig-column-actions-background-color: #292826;
    --ig-column-actions-title-color: #ffcd0f;
    --row-odd-background: #FAF9F6;
    --row-even-background: #E2E2E2;
    --row-odd-text-color: #253645;
    --row-even-text-color: #1E2F3F;

    /* Checkbox styles */
    --ig-checkbox-tick-color: #292826;
    --ig-checkbox-label-color: #ffcd0f;
    --ig-checkbox-empty-color: #ffcd0f;
    --ig-checkbox-fill-color: #ffcd0f;

    /* Input styles */
    --ig-input-group-idle-text-color: white;
    --ig-input-group-filled-text-color: #202020;
    --ig-input-group-focused-text-color: #202020;
    --ig-input-group-focused-border-color: #ffcd0f;
    --ig-input-group-focused-secondary-color: #ffcd0f;

    /* Buttons styles */
    --ig-button-foreground: #202020;
    --ig-button-background: #d4d4d4;
    --ig-button-hover-background: #404040;
    --ig-button-hover-foreground: #ffcd0f;
    --ig-button-focus-background: #1E497D;
    --ig-button-focus-foreground: black;
    --ig-button-focus-visible-background: #ffcd0f;
    --ig-button-focus-visible-foreground: black;
    --ig-button-disabled-foreground: #1E497D;
    --ig-paginator-button: #00FF00;
}

.ig-grid-toolbar-actions button {
    background: none !important;
}

.ig-grid-toolbar-actions button {
    width: 12px !important;  /* Adjust size as needed */
    height: 12px !important; /* Adjust size as needed */
}

.ig-grid-toolbar-actions button svg {
    width: 10px !important;  /* Adjust size as needed */
    height: 10px !important; /* Adjust size as needed */
}

.ig-grid-toolbar-actions button[title="Add Row"] {
    border: none;
}

.custom-paginator-button {
    background-color: #007bff;
    /* Blue background */
    color: #fff;
    /* White text color */
    border: none;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 4px;
    /* Rounded corners */
}

@media (max-width: 768px) {
    .gridDataContainer {
        height: 400px;
    }
}

.context-menu {
    background-color: #FAE5A4;
    border: 1px solid #171717;
    border-radius: 10px;
    color: #0f0f0f;
}

.context-menu-hover :hover {
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.button-2 {
    background-color: rgba(51, 51, 51, 0.05);
    border-radius: 8px;
    border-width: 0;
    color: #333333;
    cursor: pointer;
    display: inline-block;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    padding: 10px 12px;
    text-align: center;
    transition: all 200ms;
    vertical-align: baseline;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.dateFormatDispay {
    font-family: 'Montaga', sans-serif;
}

/* Terms of Service */

.TnS {
    font-family: 'Arial';
}

/* Modal Styles */

.modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.modal-content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 15px;
}

.close {
    float: right;
    cursor: pointer;
}

.headerTitle,
.close {
    font-family: 'Montaga', sans-serif;
    font-size: large;
    color: #202020
}

.button-11 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
    border-radius: 6px;
    color: #3D3D3D;
    background: #fff;
    border: none;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-11:focus {
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
    outline: 0;
}

.button-11:hover {
    background: #505050;
    color: #FAF9F6;
}


.button-11-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
    border-radius: 6px;
    color: #EEFFFF;
    background: #1E497D;
    border: none;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-11-1:focus {
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
    outline: 0;
}

.button-11-1:hover {
    background: #505050;
    color: #FAF9F6;
}

.smallText {
    font-family: 'Montserrat', serif;
}

.formsMenu {
    position: relative;
}

.formsSubMenu {
    position: absolute;
    top: 0;
    left: 100%;
    margin-left: 0px;
    list-style-type: none;
    z-index: 2;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888888;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555555;
    }
}