p,
h1,
a,
label {
	font-family: Arial, Helvetica, sans-serif;
}

.glass1 {
	/* background: rgba(152, 208, 249, 0.7); */
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(3.1px);
	-webkit-backdrop-filter: blur(3.1px);
	border: 1px solid rgba(152, 208, 249, 0.44);
}

.glass2 {
	background: rgba(44, 177, 191, 0.58);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(18.1px);
	-webkit-backdrop-filter: blur(18.1px);
	border: 1px solid rgba(44, 177, 191, 0.69);
}

.loginBtnContainer {
	padding-top: 2%;
	justify-content: center;
	display: flex;
	width: 100%;
}

.loginButton {
	border: 1px solid #000;
	border-radius: 15px;
	padding: 0.7em;
	width: 40%;
	color: #212121;
	background-color: #c2d3e5;
	font-weight: 600;
}

#website-admin::placeholder,
#website-password::placeholder,
#website-admin,
#website-password {
	color: #06142E;
	opacity: 0.7;
}

.bottomContainer {
	padding-top: 2%;
}

.passwordContainer {
	font-size: 16px;
	color: #FF0000;
	cursor: pointer;
	align-items: center;
	align-content: center;
	align-self: center;
}

.policyContainer {
	padding-top: 2%;
	font-size: 14px;
}

a {
	color: #0000EE;
	text-decoration: underline;
}

.button-86 {
	all: unset;
	width: 100px;
	height: 30px;
	font-size: 16px;
	background: transparent;
	border: none;
	position: relative;
	color: #f0f0f0;
	cursor: pointer;
	z-index: 1;
	padding: 10px 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
}

.button-86::after,
.button-86::before {
	content: '';
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: -99999;
	transition: all .4s;
}

.button-86::before {
	transform: translate(0%, 0%);
	width: 100%;
	height: 100%;
	background: #28282d;
	border-radius: 10px;
}

.button-86::after {
	transform: translate(10px, 10px);
	width: 35px;
	height: 35px;
	background: #ffffff15;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border-radius: 50px;
}

.button-86:hover::before {
	transform: translate(5%, 20%);
	width: 110%;
	height: 110%;
}

.button-86:hover::after {
	border-radius: 10px;
	transform: translate(0, 0);
	width: 100%;
	height: 100%;
}

.button-86:active::after {
	transition: 0s;
	transform: translate(0, 5%);
}

@media screen {
	.App {
		padding: 5%;
	}
}

@media (max-width: 1024px) {
	.App {
		padding: 5%;
	}
}

@media (min--moz-device-pixel-ratio: 2),
(-o-min-device-pixel-ratio: 2/1),
(-webkit-min-device-pixel-ratio: 2),
(min-device-pixel-ratio: 2) {
	.App {
		padding: 0;
	}
}

/* Dashboard Styles */
.dashboardContainer {
	flex: 1;
	background-color: #FAF9F6;
	color: #D3D4D6;
	display: flex;
}

aside {
	width: 15%;
	background-color: #1E497D;
	color: #D3D4D6;
	flex: '0 0 15%';
	position: static;
}

aside.collapsed {
	width: 5%;
	flex: '0 0 5%'
}

section {
	width: '75%';
	flex: '1 0 75%';
}

/* 404 */

.errorBg {
	background: url('../assets/404_Error.png') no-repeat center center fixed;
	background-color: #4dfcff;
	height: 100vh;
}

.bottomBtn {
	position: absolute;
	bottom: 100px;
	left: 50%;
	transform: translateX(-50%);
}

.btn {
	padding: 10px 20px;
	border-radius: 5px;
	border: none;
	background: purple;
	color: white;
	cursor: pointer;
}

/* Auth Failure Page */

.authFailureBG {
	background-color: #4dfcff;
}

/* DMS Connectivity */
.modalTop-body,
.modal-body,
.common-modal-body {
	color: #1C1C1C;
}

.textInput {
	border: none;
	border-radius: 5px;
	border-bottom: 1px solid #1A1A1A;
}

.sampleLabel,
.textInput,
.textInput::placeholder {
	font-family: 'Montaga', sans-serif;
	font-size: small;
}

.headerText {
	font-family: 'Montaga', sans-serif;
}

.button-6 {
	align-items: center;
	background-color: #FFFFFF;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: .25rem;
	box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
	box-sizing: border-box;
	color: rgba(0, 0, 0, 0.85);
	cursor: pointer;
	display: inline-flex;
	font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	justify-content: center;
	line-height: 1.25;
	margin: 0;
	min-height: 3rem;
	padding: calc(.875rem - 1px) calc(1.5rem - 1px);
	position: relative;
	text-decoration: none;
	transition: all 250ms;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	vertical-align: baseline;
	width: auto;
}

.button-6:hover,
.button-6:focus {
	border-color: rgba(0, 0, 0, 0.15);
	box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
	color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
	transform: translateY(-1px);
}

.button-6:active {
	background-color: #F0F0F1;
	border-color: rgba(0, 0, 0, 0.15);
	box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
	color: rgba(0, 0, 0, 0.65);
	transform: translateY(0);
}

/* ForgotPassword designs */
.bgShadow {
	/* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
	background: rgba(41, 39, 39, 0.26);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(6.5px);
	-webkit-backdrop-filter: blur(6.5px);
	border: 1px solid rgba(41, 39, 39, 0.91);
}

.button-56 {
	align-items: center;
	background-color: #fee6e3;
	border: 2px solid #111;
	border-radius: 8px;
	box-sizing: border-box;
	color: #111;
	cursor: pointer;
	display: flex;
	font-family: Inter, sans-serif;
	font-size: 16px;
	height: 48px;
	justify-content: center;
	line-height: 24px;
	max-width: 100%;
	padding: 0 25px;
	position: relative;
	text-align: center;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
}

.button-56:after {
	background-color: #111;
	border-radius: 8px;
	content: "";
	display: block;
	height: 48px;
	left: 0;
	width: 100%;
	position: absolute;
	top: -2px;
	transform: translate(8px, 8px);
	transition: transform .2s ease-out;
	z-index: -1;
}

.button-56:hover:after {
	transform: translate(0, 0);
}

.button-56:active {
	background-color: #ffdeda;
	outline: 0;
}

.button-56:hover {
	outline: 0;
}

@media (min-width: 768px) {
	.button-56 {
		padding: 0 40px;
	}
}

.button-32 {
	background-color: #fff000;
	border-radius: 12px;
	color: #000;
	cursor: pointer;
	font-weight: bold;
	padding: 10px 15px;
	text-align: center;
	transition: 200ms;
	width: 100%;
	box-sizing: border-box;
	border: 0;
	font-size: 16px;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	transition: background-color 0.3s ease;
}

.button-32:not(:disabled):hover,
.button-32:not(:disabled):focus {
	outline: 0;
	background: #f4e603;
	box-shadow: 0 0 0 2px rgba(0, 0, 0, .2), 0 3px 8px 0 rgba(0, 0, 0, .15);
}

.button-32:disabled {
	filter: saturate(0.2) opacity(0.5);
	-webkit-filter: saturate(0.2) opacity(0.5);
	cursor: not-allowed;
}

.button-32.sparkle::before {
	content: "";
	position: absolute;
	top: -50%;
	left: -50%;
	width: 200%;
	height: 200%;
	background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 65%);
	animation: sparkle 2s infinite;
}

@keyframes sparkle {
	0% {
		transform: translate(-50%, -50%) rotate(0deg) scale(0);
		opacity: 1;
	}

	100% {
		transform: translate(-50%, -50%) rotate(360deg) scale(2);
		opacity: 0;
	}
}

.loader1 {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	border: 3px solid;
	border-color: #212121 #212121 transparent transparent;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.loader1::after,
.loader1::before {
	content: '';
	box-sizing: border-box;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border: 3px solid;
	border-color: transparent transparent #FF3D00 #FF3D00;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	box-sizing: border-box;
	animation: rotationBack 0.5s linear infinite;
	transform-origin: center center;
}

.loader1::before {
	width: 32px;
	height: 32px;
	border-color: #212121 #212121 transparent transparent;
	animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes rotationBack {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(-360deg);
	}
}