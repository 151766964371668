.headerLabelStyle {
    border: none;
    color: #EEFFFF;
    font-weight: 600;
    word-spacing: 3px;
	font-family: 'Montserrat', sans-serif;
}

.glass2 {
	background: rgba(152, 208, 249, 0.7);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(3.1px);
	-webkit-backdrop-filter: blur(3.1px);
	border: 1px solid rgba(152, 208, 249, 0.44);
}