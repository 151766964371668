.main {
    /* background: url('../assets/Scan_Solutions_SplashPage.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
}

@media (max-width: 768px) {
    .main {
        background-position: unset;
    }
}

.header {
    background-color: transparent;
    padding: 0.4% 2% 0.4% 2%;
}

.header1,
.footer {
    background-color: #78AEE6;
    padding: 0.4% 2% 0.4% 2%;
}

.header {
    width: 100%;
}

.logo {
    width: 15%;
}

.contactContainer {
    display: flex;
    align-items: center;
}

.customLinkBtn {
    color: #212121;
    font-family: 'Montaga', sans-serif;
    border: 1px solid #eaeded;
    background-color: #eaeded;
    font-weight: 800;
    text-transform: uppercase;
    border-radius: 15px;
    padding: 10px 20px;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 5px 5px 7px rgba(235,235,235,0.6);
    font-size: 10px;
}

@media (max-width: 767px) {
    .logo {
        width: 25%;
    }
}

@media (min-width: 768px) {
    .logo {
        width: 20%;
    }
}

@media (min-width: 1024px) {
    .logo {
        width: 15%;
    }
}

.mainContainer {
    height: 100vh;
}

.headerLabelStyleDropDown {
    font-family: 'Montserrat', sans-serif;
}

.button-33 {
    border: none;
    color: #EEFFFF;
    font-weight: 600;
    word-spacing: 3px;
}

.headerImageContainer {
    background-color: #3498db;
    padding: 0.4% 2% 0.4% 2%;
}